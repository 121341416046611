import { FireNorm } from './norm'
import { database } from '@/utils/firebase'
import { IDepartment } from '@/utils/types'
import { FIREBASE_EMAIL_COLLECTION } from '@/utils/constants'

export class FireSendMail extends FireNorm<IDepartment> {
  // Any data send to this collection will be auto send mail
  // See https://firebase.google.com/products/extensions/firestore-send-email
  ref = database.collection(FIREBASE_EMAIL_COLLECTION)

  sendMail(toEmails: string[], subject: string, html: string) {
    const uniqueEmails = [...new Set(toEmails)]
    // Send to list off email
    uniqueEmails.map(to => {
      this.ref.add({
        to,
        message: {
          subject,
          html,
        },
      })
    })
  }
}

export default new FireSendMail()
