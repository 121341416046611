
import uniqBy from 'lodash/uniqBy'
import reverse from 'lodash/reverse'
import { Vue, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { IStaff, IUser } from '@/utils/types'
import { CalendarOutlined } from '@ant-design/icons-vue'
import FireStaff from '@/services/staffs'
import moment from 'moment'
import { formatDate } from '@/utils/helpers'

@Options({
  components: { CalendarOutlined },
  directives: { maska },
})
export default class UpComingBirthday extends Vue {
  loading = false
  modalVisible = false
  selectedStaffId = ''

  searchQuery = ''

  staffs: IStaff[] = []
  staffsFiltered: IStaff[] = []

  formatDate = formatDate

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  @Watch('staffs', { deep: true })
  staffsChanged() {
    // Get latest update items by reverse
    const staffs = reverse(uniqBy(reverse([...this.staffs]), 'id'))
    for (const staff of staffs) {
      if (staff.birthDate) {
        const bithdayMoment = moment.unix(staff.birthDate as number).set('year', moment().year())
        const next30Days = moment().add(30, 'days')
        const now = moment()
        if (bithdayMoment > now && bithdayMoment < next30Days) {
          this.staffsFiltered.push(staff)
        }
      }
    }
  }

  async getStaffs() {
    FireStaff.on((staff: IStaff) => {
      if (staff.fireChangeType === 'removed') {
        this.staffs = this.staffs.filter((item) => item.id !== staff.id)
      } else {
        this.staffs.push(staff)
      }
    })
  }

  beforeDestroy() {
    FireStaff.off()
    this.staffs = []
  }

  created() {
    this.getStaffs()
  }
}
