<template>
  <a-spin :spinning="loading">
    <div class="pt-5 px-6">
      <div class="flex items-center flex-wrap gap-4">
        <h1 class="text-2xl m-0 flex-grow">
          <DashboardOutlined />
          Dashboard
        </h1>
        <div>
          <a-button size="small" @click.prevent="goto('organization_setting')">
            <SettingOutlined />
            Edit Oganization info
          </a-button>
        </div>
      </div>

      <p class="text-gray-400 text-base mt-3">Simple Human Resource management tool</p>
    </div>
    <a-divider />

    <div class="px-6">
      <a-row :gutter="16">
        <a-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8" class="mb-4">
          <LeaveNeedToApprove />
        </a-col>
        <a-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8" class="mb-4">
          <UpComingBirthday />
        </a-col>
      </a-row>
    </div>
  </a-spin>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { DashboardOutlined, SettingOutlined } from '@ant-design/icons-vue'
import UpComingBirthday from '@/components/staff/UpComingBirthday.vue'
import LeaveNeedToApprove from '@/components/staff/LeaveNeedToApprove.vue'

@Options({
  components: { UpComingBirthday, LeaveNeedToApprove, DashboardOutlined, SettingOutlined },
  directives: { maska },
})
export default class Home extends Vue {
  loading = false

  get isAuth() {
    return this.$store.getters.isAuth
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }
}
</script>
