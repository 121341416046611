import { firebase, database } from '@/utils/firebase'
import { FireNorm } from './norm'
import { ILeave } from '@/utils/types'
import { COMPANY_ID, FIREBASE_ORGANIZATION_COLLECTION } from '@/utils/constants'
import FireStaff from './staffs'
import { leaveEmailTemplate } from './email-templates/leave-email-template'
import FireOrganization from './organization'
import FireUser from './users'
import FireSendMail from './send-emails'
import { formatDate } from '@/utils/helpers'

export class FireLeave extends FireNorm<ILeave> {
  unsubscribeSnapshot: Function = () => true

  ref = database.collection(FIREBASE_ORGANIZATION_COLLECTION).doc(COMPANY_ID).collection('leaves')

  async parseWithUser(doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>, changeType?: string) {
    const leave = this.parse<ILeave>(doc, changeType)
    if (leave.userId) {
      const staff = await FireStaff.getStaffByUserId(leave.userId)
      if (staff) {
        leave.staffInfo = staff
      }
    }

    return leave
  }

  on = (callback: Function) => {
    this.unsubscribeSnapshot = this.ref.onSnapshot(snapshot => {
      snapshot.docChanges().forEach(async (change) => {
        const item = await this.parseWithUser(change.doc, change.type)
        callback(item)
      })
    })
  }

  get = async (id: string) => {
    const doc = await this.ref.doc(id).get()
    return await this.parseWithUser(doc)
  }

  async getLeaveManagerEmails(leave: ILeave) {
    const emails: string[] = []

    for (const userId of leave.managerIds || []) {
      const user = await FireUser.get(userId)

      if (user) {
        emails.push(user.email)
      }
    }

    return emails
  }

  replaceEmailTemplateVars(templateHTML: string, leave: ILeave) {
    if (!leave.details) {
      return ''
    }

    // Replace variables
    templateHTML = templateHTML.replace('{{___name___}}', `${leave.staffInfo?.firstName} ${leave.staffInfo?.lastName}`)

    const detailsHTML: string[] = []
    for (const detail of leave.details) {
      detailsHTML.push(`<li>
        <span style="background-color: yellow;">${formatDate(detail.fromDate as number)} </span>
        <span> ~ </span>
        <span style="background-color: yellow;">${formatDate(detail.toDate as number)}</span>
        <span> | </span>
        <span style="background-color: green; color: #fff">${detail.morningEvening}</span>
      </li>`)
    }

    templateHTML = templateHTML.replace('{{___details___}}', detailsHTML.join(''))
    templateHTML = templateHTML.replace('{{___total___}}', `${leave.total}`)
    templateHTML = templateHTML.replace('{{___note___}}', `${leave.note}`)
    templateHTML = templateHTML.replace('{{___status___}}', `${leave.status}`)
    templateHTML = templateHTML.replace(
      '{{___goto_url___}}',
      `${window.location.protocol}//${window.location.hostname}/leaves/others`
    )

    return templateHTML
  }

  sendMailToApprovers = async (id: string) => {
    const leave = await this.get(id)
    if (!leave.details?.length) {
      return
    }

    const organizationInfo = await FireOrganization.get()
    let emails: string[] = []

    // Organization emails
    emails = emails.concat(organizationInfo.leavesSetting?.sendNewLeaveRequestToEmails || [])

    // Managers email
    const managerEmails = await this.getLeaveManagerEmails(leave)
    emails = emails.concat(managerEmails)

    let templateHTML = leaveEmailTemplate

    // Replace variables
    templateHTML = this.replaceEmailTemplateVars(templateHTML, leave)
    templateHTML = templateHTML.replace('{{___subtitle___}}', 'sent a leave request.')

    return FireSendMail.sendMail(emails, `[HR] Request Leave - ${organizationInfo.name}`, templateHTML)
  }

  sendMailApproved = async (id: string) => {
    const leave = await this.get(id)
    if (!leave.details?.length || !leave.userId) {
      return
    }

    const organizationInfo = await FireOrganization.get()
    let emails: string[] = []

    // Organization emails
    emails = emails.concat(organizationInfo.leavesSetting?.sendApprovedLeaveRequestEmails || [])

    // Staff request leave email
    const requestingUser = await FireUser.get(leave.userId)
    emails = emails.concat([requestingUser.email])

    let templateHTML = leaveEmailTemplate

    // Replace variables
    templateHTML = this.replaceEmailTemplateVars(templateHTML, leave)
    templateHTML = templateHTML.replace('{{___subtitle___}}', ' got approval.')

    return FireSendMail.sendMail(emails, `[HR] Leave approved - ${organizationInfo.name}`, templateHTML)
  }

  sendMailRejected = async (id: string) => {
    const leave = await this.get(id)
    if (!leave.details?.length || !leave.userId) {
      return
    }

    const organizationInfo = await FireOrganization.get()
    let emails: string[] = []
    // Staff request leave email
    const requestingUser = await FireUser.get(leave.userId)
    emails = emails.concat([requestingUser.email])

    let templateHTML = leaveEmailTemplate

    // Replace variables
    templateHTML = this.replaceEmailTemplateVars(templateHTML, leave)
    templateHTML = templateHTML.replace('{{___subtitle___}}', ' request leave rejected.')

    return FireSendMail.sendMail(emails, `[HR] Leave rejected - ${organizationInfo.name}`, templateHTML)
  }

  onMyLeaves = (userId: string, callback: Function) => {
    this.unsubscribeSnapshot = this.ref.where('userId', '==', userId).onSnapshot(snapshot => {
      snapshot.docChanges().forEach(async (change) => {
        const item = await this.parseWithUser(change.doc, change.type)
        callback(item)
      })
    })
  }

  onFromOthers = (userId: string, callback: Function) => {
    this.unsubscribeSnapshot = this.ref.where('managerIds', 'array-contains', userId).onSnapshot(snapshot => {
      snapshot.docChanges().forEach(async (change) => {
        const item = await this.parseWithUser(change.doc, change.type)
        callback(item)
      })
    })
  }
}

export default new FireLeave()
