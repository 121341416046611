export const leaveEmailTemplate = `<!doctype html>
<html>
    <head>
        <meta name="viewport" content="width=device-width">
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    </head>

    <body>
        <p>
            <strong>{{___name___}}</strong> {{___subtitle___}}
        </p>

        <hr />

        <h4>Details</h4>
        <ul>
            {{___details___}}

            <li>
                Total:
                <span style="background-color: yellow; font-weight: bold;">
                    {{___total___}} (days)
                </span>
            </li>
        </ul>

        <h4>Status</h4>
        <ul>
            <li>
                <span style="background-color: yellow; font-weight: bold;">
                    {{___status___}}
                </span>
            </li>
        </ul>

        <h4>Note</h4>
        <p style="background-color: #f2f2f2; padding: 10px;">
            {{___note___}}
        </p>

        <h4>Check it out</h4>
        <p>
            {{___goto_url___}}
        </p>
    </body>
</html>
`
