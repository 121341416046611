<template>
  <a-spin :spinning="loading">
    <a-list bordered :data-source="staffsFiltered" class="color-card">
      <template #renderItem="{ item }">
        <a-list-item>
          <a-tag color="green">
            <span class="text-sm">{{ formatDate(item.birthDate) }} </span>
          </a-tag>
          <span class="text-white font-bold">{{ item.lastName }}</span>
        </a-list-item>
      </template>
      <template #header>
        <h2 class="text-2xl text-white"><CalendarOutlined /> Upcoming birthday</h2>
      </template>
    </a-list>
  </a-spin>
</template>

<script lang="ts">
import uniqBy from 'lodash/uniqBy'
import reverse from 'lodash/reverse'
import { Vue, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { IStaff, IUser } from '@/utils/types'
import { CalendarOutlined } from '@ant-design/icons-vue'
import FireStaff from '@/services/staffs'
import moment from 'moment'
import { formatDate } from '@/utils/helpers'

@Options({
  components: { CalendarOutlined },
  directives: { maska },
})
export default class UpComingBirthday extends Vue {
  loading = false
  modalVisible = false
  selectedStaffId = ''

  searchQuery = ''

  staffs: IStaff[] = []
  staffsFiltered: IStaff[] = []

  formatDate = formatDate

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  @Watch('staffs', { deep: true })
  staffsChanged() {
    // Get latest update items by reverse
    const staffs = reverse(uniqBy(reverse([...this.staffs]), 'id'))
    for (const staff of staffs) {
      if (staff.birthDate) {
        const bithdayMoment = moment.unix(staff.birthDate as number).set('year', moment().year())
        const next30Days = moment().add(30, 'days')
        const now = moment()
        if (bithdayMoment > now && bithdayMoment < next30Days) {
          this.staffsFiltered.push(staff)
        }
      }
    }
  }

  async getStaffs() {
    FireStaff.on((staff: IStaff) => {
      if (staff.fireChangeType === 'removed') {
        this.staffs = this.staffs.filter((item) => item.id !== staff.id)
      } else {
        this.staffs.push(staff)
      }
    })
  }

  beforeDestroy() {
    FireStaff.off()
    this.staffs = []
  }

  created() {
    this.getStaffs()
  }
}
</script>
<style lang="scss" scoped>
.color-card {
  border: none;
  background: #fc4a1a;
  background: -webkit-linear-gradient(to right, #fc4a1a, #f7b733);
  background: linear-gradient(to right, #fc4a1a, #f7b733);
  h2 {
    color: #fff;
  }
}
</style>
