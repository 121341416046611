
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { DashboardOutlined, SettingOutlined } from '@ant-design/icons-vue'
import UpComingBirthday from '@/components/staff/UpComingBirthday.vue'
import LeaveNeedToApprove from '@/components/staff/LeaveNeedToApprove.vue'

@Options({
  components: { UpComingBirthday, LeaveNeedToApprove, DashboardOutlined, SettingOutlined },
  directives: { maska },
})
export default class Home extends Vue {
  loading = false

  get isAuth() {
    return this.$store.getters.isAuth
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }
}
