<template>
  <a-spin :spinning="loading">
    <a-list bordered :data-source="filterLeaves" class="color-card">
      <template #renderItem="{ item }">
        <a-list-item @click="gotoNeedToApproveList" class="cursor-pointer">
          <a-tag color="green">
            <span class="text-sm"> {{ item.staffInfo?.firstName }} {{ item.staffInfo?.lastName }} </span>
          </a-tag>

          <a-tag color="green">{{ item.total }} </a-tag>
        </a-list-item>
      </template>
      <template #header>
        <h2 class="text-2xl text-white"><CalendarOutlined /> Leave need to approve</h2>
      </template>
    </a-list>
  </a-spin>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { ELeaveStatus, ILeave, IStaff, IUser } from '@/utils/types'
import { CalendarOutlined } from '@ant-design/icons-vue'
import { formatDate } from '@/utils/helpers'
import FireLeave from '@/services/leaves'

@Options({
  components: { CalendarOutlined },
  directives: { maska },
})
export default class LeaveNeedToApprove extends Vue {
  loading = false
  modalVisible = false
  selectedStaffId = ''

  searchQuery = ''

  leaves: ILeave[] = []

  needToApproveList: IStaff[] = []

  formatDate = formatDate

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get filterLeaves() {
    return this.leaves.filter((leave) => leave.status === ELeaveStatus.waiting)
  }

  beforeDestroy() {
    this.needToApproveList = []
  }

  gotoNeedToApproveList() {
    this.$router.push({
      name: 'leaves',
      params: {
        tab: 'others',
      },
    })
  }

  async created() {
    if (!this.userInfo.id) {
      return
    }

    FireLeave.onFromOthers(this.userInfo.id, (leave: ILeave) => {
      if (leave.fireChangeType === 'removed') {
        this.leaves = this.leaves.filter((item) => item.id !== leave.id)
      } else {
        this.leaves.push(leave)
      }
    })
  }
}
</script>
<style lang="scss" scoped>
.color-card {
  border: none;
  background: #11998e;
  background: -webkit-linear-gradient(to right, #11998e, #38ef7d);
  background: linear-gradient(to right, #11998e, #38ef7d);

  h2 {
    color: #fff;
  }
}
</style>
